<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form class="auth__form" @submit.prevent="handleSubmit(createUser)">
					<Logo />
					<div class="mb-5 text-center">
						<h1 class="title is-size-3 is-bold m-0">Cadastro</h1>
						<!-- prettier-ignore -->
						<p>Já tem uma conta? <router-link :to="{ name: 'Login' }" class="is-semibold has-text-secondary">Faça o Login!</router-link></p>
					</div>

					<div class="columns mb-5">
						<div class="column pl-0">
							<InputWithValidation tab="1" rules="required|min:3" type="text" name="firstname" label="Nome" v-model="auth.first_name" />
						</div>
						<div class="column pr-0">
							<InputWithValidation tab="2" rules="required|min:3" type="text" name="lastname" label="Sobrenome" v-model="auth.last_name" />
						</div>
					</div>

					<InputWithValidation tab="3" class="mb-5" rules="required|email" type="email" name="email" label="E-mail" v-model="auth.email" />

					<InputWithValidation tab="4" rules="required|min:8" type="password" name="password" label="Senha" vid="password" password-reveal v-model="auth.password" />

					<password-meter class="mb-5" :password="auth.password" @score="Score" />

					<span class="is-block text-center">
						<b-button tabindex="5" native-type="submit" class="button is-button is-secondary" :loading="loading">Enviar</b-button>
					</span>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import PasswordMeter from 'vue-simple-password-meter'
import { ToastProgrammatic as Toast } from 'buefy'
import Api from '@/services/api'

export default {
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	data() {
		return {
			loading: false,
			auth: {
				first_name: '',
				last_name: '',
				email: '',
				password: ''
			}
		}
	},
	methods: {
		async createUser() {
			try {
				this.loading = true
				const response = await Api.post('user/register', this.auth)
				const { status } = response
				if (status === 201) {
					const { token } = response.data
					localStorage.setItem('@podash:token', token)
					await this.$router.push('dashboard')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
				}
			} finally {
				this.loading = false
			}
		},
		Score({ score, strength }) {
			console.log(score, strength) // score: from 0 to 4 - strength: 'risky', 'guessable', 'weak', 'safe' , 'secure'
			this.score = score
		}
	}
}
</script>
